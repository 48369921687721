import * as React from 'react'
import { Link } from 'gatsby'

import {
  container,
  currentSelection
} from '@styles/pagination.module.scss'

const Pagination = ({ currentPage, pageCount, base }) => {
  return (
    <div className={`${container} section`}>
      {currentPage > 1 && (
        <Link to={`/${base}/page/${currentPage - 1}`}>
         <button>Back</button>
        </Link>
      )}
      {Array.from({length: pageCount}).map((_, index) => (
        <Link to={`/${base}/page/${index + 1}`} className={index + 1 === currentPage && currentSelection}>
          <button>{index + 1}</button>
        </Link>
      ))}
      {currentPage < pageCount && (
        <Link to={`/${base}/page/${currentPage + 1}`}>
         <button>Next</button>
        </Link>
      )}
    </div>
  )
}

export default Pagination