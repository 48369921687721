import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import Pagination from '@components/pagination'

import {
  postContainer,
  postImage,
  postFade,
  postText
} from '@styles/article-pagination.module.scss'

const ArticlePagination = ({ data, pageContext }) => {
  return (
    <Layout pageTitle="Articles">
      <Container>
        <Row>
          <Col>
            <div className="hero-banner large-hero light-hero">
              <StaticImage
                className="hero-image"
                src="../media/wessuc_default_hero.png"
                alt=""
                aspectRatio={4 / 1}
                layout="fullWidth" />
              <div className="hero-text">
                <h1>Articles</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
        <main>
          <Container fluid="xxl">
            <Row xl={2} lg={2} md={2} sm={1} xs={1}>
              {data.allPrismicArticle.edges.map(({ node }) => (
                <Col>
                  <Link
                    to={`/articles/${node.uid}`}
                    className={postContainer}>
                    {node.data.article_thumbnail && node.data.article_thumbnail.gatsbyImageData
                      ? <GatsbyImage className={postImage} image={node.data.article_thumbnail.gatsbyImageData} />
                      : '' }
                    <div className={postFade}>
                      <div className={postText}>
                        <div>{node.data.title.text}</div>
                        <div>{node.last_publication_date}</div>
                      </div>
                    </div>
                  </Link>
                </Col>
            ))}
            </Row>
          </Container>
          <Container>
            <Row>
              <Col>
                <Pagination
                  pageCount={pageContext.pageCount}
                  currentPage={pageContext.currentPage}
                  base="articles"
                />
              </Col>
            </Row>
          </Container>
          
        </main>
    </Layout>
  )
}

  export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allPrismicArticle(
      sort: { fields: last_publication_date, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          _previewable
          id
          uid
          data {
            title {
              text
            }
            article_thumbnail {
              gatsbyImageData
            }
          }
          last_publication_date(formatString: "MMM DD, YYYY")
        }
      }
    }
  }
`

export default ArticlePagination
